<template>
    <div class="home">
        <div class="body-block body-left">
            <div class="logo">
                <img src="../assets/icon_logo_web.png" alt="magic lamp">
            </div>
            <div class="text1">
                Silent AI
            </div>
            <div class="text2">
                Silent AI can help you fall asleep faster
            </div>
            <div class="qrcode-inline">
                <div class="qrcode-box">
                    <div id="qrcode1" ref="qrcode1"></div>
                    <img class="store-block" src="../assets/icon_web_as.png" alt="">
                </div>
                <div class="qrcode-box">
                    <div id="qrcode2" ref="qrcode2"></div>
                    <img class="store-block" src="../assets/icon_web_gp.png" alt="">
                </div>
            </div>
        </div>
        <div class="body-block body-right">
<!--            <div class="dwn-btn">-->
<!--                Download now-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'

    import QRCode from "qrcodejs2";

    export default {
        name: 'Home',
        data() {
            return {
                //苹果
                // link1: "http://dl.smart.silentai.cn/appstore",
                link1: "https://dl.smart.silentai.cn/lamp/appstore",
                //google
                // link2: "http://dl.smart.silentai.cn/googleplay",
                link2: "https://dl.smart.silentai.cn/lamp/googleplay",
                code1: null,
                code2: null,
            };
        },
        mounted() {
            this.showQRcode();
        },
        methods: {
            qrcode1(qWidth, qHeight, qText, qRender) {
                this.code1 = new QRCode("qrcode1", {
                    width: qWidth,
                    height: qHeight,
                    text: qText,
                    render: qRender
                });
            },
            qrcode2(qWidth, qHeight, qText, qRender) {
                this.code2 = new QRCode("qrcode2", {
                    width: qWidth,
                    height: qHeight,
                    text: qText,
                    render: qRender
                });
            },
            showQRcode() {
                //二维码初始化 点击一次添加一个二维码
                this.$refs.qrcode1.innerHTML = "";
                this.$refs.qrcode2.innerHTML = "";
                this.$nextTick(function () {
                    this.qrcode1(160, 160, this.link1, "canvas");
                    this.qrcode2(160, 160, this.link2, "canvas");
                });
            }
        }
    }
</script>

<style scoped>

    .home {
        width: 100%;
        height: 100%;
        background-color: #2a2f35;
        min-height: 1080px;
    }

    .logo {
        text-align: center;
    }

    .logo img {
        margin-top: 24px;
    }

    .body-block {
        width: 50%;
        display: inline-block;
        vertical-align: top;
    }

    .body-right {
        background: url("../assets/img_web.png") 0 0 no-repeat;
        background-size: 100% auto;
        height: 100%;
    }

    .dwn-btn {
        width: 200px;
        height: 50px;
        background-color: #4777f5;
        border-radius: 10px;
        text-align: center;
        line-height: 50px;
        margin: 35px auto 0 auto;
        font-size: 24px;
        color: #ffffff;

        cursor: pointer;
    }

    .text1 {
        width: 476px;
        height: 85px;
        font-size: 90px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #ffffff;

        margin: 30% auto 0 auto;
    }


    .text2 {
        text-align: left;
        width: 531px;
        height: 85px;
        font-size: 40px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;

        margin: 45px auto 0 auto;
    }

    .qrcode-inline {
        margin-top: 50px;
    }

    .qrcode-box {
        display: inline-block;
        vertical-align: top;
        margin: 0 25px;
        text-align: center;
        padding: 15px 20px 0 20px;
        /*height: 240px;*/

        background: #ffffff;
    }

    .store-block {
        margin: 15px 0 15px 0;

        width: 160px;
    }

</style>
